import { LOADING_RESOURCE } from "./../../actionType";
import { PayloadAction } from "@reduxjs/toolkit";
import { Platform, ReviewSourceCompany } from "../../../interface/reviewSource";
import {
  EDIT_REVIEW_SOURCE,
  GET_PLATFORM_LIST,
  GET_RESOURCE_LIST,
  MODAL_RESOURCE,
  PLATFORM_TO_HANDLE,
  REVIEW_SOURCE_COMPANY,
  USER_REVIEW_SOURCE_MODAL,
  GET_REVIEWSOURCE_BY_COMPANYID
} from "../../actionType";

interface reviewSourceType {
  isLoading: boolean;
  reviewSourceList: Array<Platform>;
  platformList: Array<Platform>;
  reviewSourceTotal: number;
  isOpenModal: boolean;
  OpenModalUserRS: boolean;
  reviewSourceEdit: any;
  reviewSourceCompany: ReviewSourceCompany[];
  reviewSourceCompanyId: ReviewSourceCompany[];
  platformToHanlde: any;
}
const initialState: reviewSourceType = {
  isLoading: false,
  reviewSourceList: [],
  platformList: [],
  reviewSourceTotal: 0,
  isOpenModal: false,
  OpenModalUserRS: false,
  reviewSourceEdit: {},
  reviewSourceCompany: [],
  platformToHanlde: {},
  reviewSourceCompanyId: []
};
const reviewSourceReducer = (
  state = initialState,
  action: PayloadAction<any>
): reviewSourceType => {
  switch (action.type) {
    case GET_RESOURCE_LIST: {
      return {
        ...state,
        reviewSourceList: action.payload.results,
        reviewSourceTotal: action.payload.count,
        isLoading: false,
      };
    }
    case MODAL_RESOURCE: {
      return {
        ...state,
        isOpenModal: action.payload,
      };
    }
    case USER_REVIEW_SOURCE_MODAL: {
      return {
        ...state,

        OpenModalUserRS: action.payload,
      };
    }
    case EDIT_REVIEW_SOURCE: {
      return {
        ...state,
        isLoading: false,
        reviewSourceEdit: action.payload,
      };
    }

    case REVIEW_SOURCE_COMPANY: {
      return {
        ...state,
        isLoading: false,
        reviewSourceCompany: action.payload,
      };
    }
    case GET_PLATFORM_LIST: {
      return {
        ...state,
        isLoading: false,
        platformList: action.payload,
      };
    }
    case PLATFORM_TO_HANDLE: {
      return {
        ...state,
        platformToHanlde: action.payload,
      };
    }
    case LOADING_RESOURCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
	case GET_REVIEWSOURCE_BY_COMPANYID: {
		return {
			...state,
			reviewSourceCompanyId: action.payload
		}
	}
    default:
      return state;
  }
};
export default reviewSourceReducer;
